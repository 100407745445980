import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Seo from "../components/seo"
import { val }  from '../components/cssvalue'
import {PanelWrap} from '../pages/index.js'
import {MainWrap, SectionDetailPlan, PanelWrapPrice, ListSmall} from '../pages/web.js'

// STYLE
const PanelWrapFaq = styled(PanelWrap)`
  a {
    color: ${val.BLACK};
  }
  .title {
    text-indent: -1.3em;
    padding: 10px 10px 10px 1.8em;
  }
  .answer {
    text-indent: -.9em;
    margin-left: 1.3em;
  }
`;
const SectionArticleFaq = styled.div`
  background: ${val.BG};
  width: 49%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 14px;
`;
const TitleFaq = styled.div`
  background:  ${val.WHITE};
  padding: 3px 10px;
  font-weight: bold;
`;
const Faq = ({data, location}) => {
  return (
    <Layout location={location}>
      <Seo
        title="よくあるご質問"
        description=""
        pagepath={location.pathname}
      />
      <MainWrap>
        <div className="-jacket">
          <h1>よくあるご質問</h1>
          <PanelWrapFaq className="-inner">
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:大きなウェブ制作会社ではなく小さなフリーの事務所にホームページ制作を依頼するメリットはなんですか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:大きなウェブ制作会社に依頼すると、実際に担当されるのがどのようなスキルのある人なのかわかりません。経験の浅い新人が対応することもありますし、自社で対応するのではなく下請けの会社やフリーランスが実作業をおこなうことも多いです。<br />
                    弊事務所で実際に対応するのは経験20年以上のベテランである本人たちとなりますのでクオリティは高いですし、中抜きがないのでホームページの価格も安くなり、大手の制作会社に依頼したのと同じかより良い成果物が3分の1くらいの価格となることも珍しくありません（弊所の「エンタープライズプラン」を大手制作会社に依頼する場合は1,000万円以上の案件となるでしょう）。<br />
                    デメリットとしては、人数が少ないため一度に対応できる数が少なく、ご依頼のボリュームとタイミング次第ではかなりお待ち頂くことがあるかもしれません。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:ホームページの価格というかホームページの作成の必要な費用はどのくらいですか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:最低55,000円（税込）となっておりますが、ホームページの目的や規模、ご予算により数百万円かかる事もあります。<br />
                  <Link to="/web/">プランのページ</Link>をご確認いただくか、お問い合わせください。
                  </p>
              </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:ホームページの納期はどれくらいですか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:<Link to="/web/">プラン表にそれぞれのプランの目安</Link>が書かれていますが、そのときの状況により異なります。お気軽にご相談ください。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:ホームページの管理や運用にはどれくらい費用がかかりますか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:まずサーバーやドメインの料金が実費としてかかりますので、サイトの規模にもよりますが最低でも月数百円〜は必要です。更新がないシンプルなホームページならほかにはかかりません（通信費や電気代等を除く）。また、外部サービスを利用する場合はそちらで別途費用がかかります。<br />
                  記事の更新をお任せ頂いたりシステム保守が必要な場合は、別途保守運用費用のご依頼をご相談させて頂くこともありますが、必要な都度ご依頼いただくこともできますし、他の制作会社様にご依頼いただいても構いません。制作開始前のお打ち合わせにてご相談させていただきます。<br />
                  ※小規模サイトでドメインも独自ドメインではない場合は、すべて無料のサービスもございます。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:自分で更新をしたい。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:更新したいのが「お知らせ」のようなコンテンツだけなのか、記事を追加していきたいのか、ホームページの内容すべてなのか、によって異なりますが、「CMSプラン」か「ビジネスプラン」以上であればある程度ご自分で更新が可能です。<br />
                  「お手軽プラン」などでも、「お知らせ機能」のみであれば追加オプションで可能となります。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:ホームページやインターネットのことがまったくわからないのですが大丈夫でしょうか。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:大丈夫です。<br />
                  なぜホームページが必要なのかホームページを作る目的や、どのようなホームページが欲しいのか、ご依頼者様側がどのような体制にあるのかなど、丁寧にヒアリングさせていただきます。<br />
                  お話をしていくうえでホームページではなく他の手段のほうがよさそうであれば、プロとして責任をもってご提案させていただきます。
                  もちろん相談は無料です。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:クレジットカードでお支払いできますか？費用のお支払い方法と時期を教えてください。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:ホームページ作成やデザインの費用は、銀行振込かクレジットカード（VISA, MasterCard, AMEX、JCB）によりお支払いいただきます。<br />
                  ご契約完了後制作着手前に着手金としてご費用の半額をお支払いいただき、完了納品後30日以内を目処に残額をお支払いいただきます。<br />
                  ホームページの保守など月毎のご契約をご希望される場合のお支払いにつきましては、ご契約前にご相談させていただきます。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:商取引法などホームページを作るうえで関連する法律がよくわからないのですが大丈夫でしょうか。<br />
                  ネットショップの開業に関わる法律もよくわかりません。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A: ホームページの3点セットともいわれる「特定商取引法に関する表記」「利用規約」「プライバシーポリシー」につきましては、追加オプション対応となりますが作成もおこなっております。
                  開業につきましては別途コンサル対応もさせていただきますので、お気軽にご相談ください。<br />
                  法の知識が豊富なスタッフもおりますが、弁護士ではないので法律相談に乗ることはできません（非弁行為という違法行為となってしまいますので）。
                  </p>
                  <p className="-p">
                  特にネットショップ（ECサイト）を作る場合には関連する法律が多くなります。特定商取引法のほか、中古品を扱うなら古物商の取得が必要なように、化粧品や食品など扱う物によっていろいろな法律があります。初めての開業なら開業届等も必要で、ご自分で勉強するか弁護士に相談する必要があるでしょう。<br />
                  ホームページ作成のプロとして、法律相談とならない範囲で開業等もご相談に乗らせていただきます。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:フリーランスの事務所だと契約が安全かどうか不安です。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:弊所には法学部出身で行政書士となる資格をもつスタッフがおります。<br />
                  ご契約前にお見積書をご提出させていただき、ご契約時には基本的に書類（紙または電子）による契約書を交わさせていただきます。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:最終的な金額が変わることはありますか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:ホームページやデザインの作成を進めていくなかで、当初のお打ち合わせになかった機能を追加する事になったり、内容がより軽微になったりした場合には、新たなお見積もりをご提出させていただきます。合意がいただけましたら、新たなお見積もりが最終的な金額となります。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:ドメインやメールアドレスなども欲しい。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:どのプランでも取得代行をさせていただきます。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:アダルトサイト、性風俗業のサイト、ギャンブル、公序良俗に反するサイト等の制作やデザインは対応していますか？
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:お断りさせていただいております。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
            <SectionArticleFaq>
              <div className="-inner">
                <TitleFaq className="title">
                  Q:営業時間を教えてください。
                </TitleFaq>
                <div className="answer">
                  <p className="-p">
                  A:基本的には平日の9:00から18:00となっておりますが、メールによるご連絡はいつでも大丈夫です。
                  </p>
                </div>
              </div>
            </SectionArticleFaq>
          </PanelWrapFaq>
        </div>
      </MainWrap>
      <Contact />
    </Layout>
  )
}
export default Faq
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
